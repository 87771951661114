<template>
  <ui-component-drawer
    v-if="showDrawer"
    drawerTitle=""
    :showDrawer="showDrawer"
    :onClickClose="onClickClose"
    position="right"
  >
    <template
      v-slot:content
      tag="div"
      style="postion: relative;"
    >
      <div class="wrapper is-unselectable">
        <div class="card has-margin-bottom-x2">
          <div class="card-content">
            <div class="wrapper-profileData has-padding has-text-centered">
              <ProfileImage
                class="is-128x128 has-margin-bottom"
                :size="150"
                :id="profile.Id"
                :image="profile.Image"
              />
              <div
                class="is-size-4 has-text-weight-bold"
                v-html="profile.FullName"
              ></div>
            </div>
            <footer class="card-footer">
              <p class="card-footer-item">
                <a
                  :href="myDashboardUrl"
                  v-html="
                      $t(
                        'Components.MainSiteElements.LoggedInProfile.BackToMyDashboard'
                      )
                    "
                ></a>
              </p>
              <p class="card-footer-item">
                <button
                  class="button-logOff button is-warning"
                  @click="logoff"
                  v-html="
                    $t('Components.MainSiteElements.LoggedInProfile.LogOff')
                  "
                ></button>
              </p>
            </footer>
          </div>
        </div>
      </div>
    </template>
  </ui-component-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const ProfileImage = () =>
  import(/* webpackChunkName: "topbar" */ '@/components/UI/ProfileImage')
export default {
  name: 'drawer-profile',
  props: {
    profile: {
      type: Object,
      required: true,
    },

    showDrawer: {
      type: Boolean,
      default: false,
    },

    onClickClose: {
      type: Function,
      default: () => { },
    },
  },

  components: {
    ProfileImage,
  },

  data() {
    return {
      myDashboardUrl: this.$router.resolve({ name: 'my-account-dashboard' }).href
    }
  },

  computed: {
  },

  methods: {
    ...mapActions('profileStore', ['logoff']),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/template";

.button-logOff {
  width: 100%;
}
</style>
