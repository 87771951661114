var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDrawer)?_c('ui-component-drawer',{attrs:{"drawerTitle":"","showDrawer":_vm.showDrawer,"onClickClose":_vm.onClickClose,"position":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"wrapper is-unselectable"},[_c('div',{staticClass:"card has-margin-bottom-x2"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"wrapper-profileData has-padding has-text-centered"},[_c('ProfileImage',{staticClass:"is-128x128 has-margin-bottom",attrs:{"size":150,"id":_vm.profile.Id,"image":_vm.profile.Image}}),_c('div',{staticClass:"is-size-4 has-text-weight-bold",domProps:{"innerHTML":_vm._s(_vm.profile.FullName)}})],1),_c('footer',{staticClass:"card-footer"},[_c('p',{staticClass:"card-footer-item"},[_c('a',{attrs:{"href":_vm.myDashboardUrl},domProps:{"innerHTML":_vm._s(
                    _vm.$t(
                      'Components.MainSiteElements.LoggedInProfile.BackToMyDashboard'
                    )
                  )}})]),_c('p',{staticClass:"card-footer-item"},[_c('button',{staticClass:"button-logOff button is-warning",domProps:{"innerHTML":_vm._s(
                  _vm.$t('Components.MainSiteElements.LoggedInProfile.LogOff')
                )},on:{"click":_vm.logoff}})])])])])])]},proxy:true}],null,false,2279049454)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }